import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
function Statistics(props: any) {
  // Find the longest length among the objects
  let analysis = props.analysis;
  let url = props.url || "/?";

  let [currencies, setCurrencies] = useState<any>({});
  let [currenciesNames, setCurrenciesNames] = useState<any>([]);

  useEffect(() => {
    // Assuming props.summary.analysis.currencies is the object you want to start with
    let analysisCurrencies = props.analysis.currencies;
    let newCurrencies = { ...analysisCurrencies }; // Create a shallow copy

    delete newCurrencies["USD"]; // Remove "USD" key
    let currencyNames = Object.keys(newCurrencies);

    let updatedCurrencies: any = {};

    for (let index = 0; index < currencyNames.length; index++) {
      let currency = currencyNames[index];
      let currencyRate = newCurrencies[currency];

      if (currencyRate < 1) {
        // Convert HKD to USD/HKD rate and update the key
        updatedCurrencies["USD/" + currency] = (1 / currencyRate).toFixed(3);
      } else {
        // Just update the key
        if (currency) {
          updatedCurrencies[currency + "/USD"] = currencyRate.toFixed(3);
        }
      }
    }

    // Now set the currencies state with the updated values
    setCurrencies(updatedCurrencies);
    setCurrenciesNames(Object.keys(updatedCurrencies));
  }, [props.analysis.currencies]);

  const strategyKeys = Object.keys(analysis.strategyNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.strategyNAVPercentage[b]["percentage"]) - parseFloat(analysis.strategyNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });

  const durationKeys = Object.keys(analysis.duration).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.duration[b]) - parseFloat(analysis.duration[a]); // For all other cases, sort alphabetically
  });
  const longShortKeys = Object.keys(analysis.longShort).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.longShort[b]) - parseFloat(analysis.longShort[a]); // For all other cases, sort alphabetically
  });

  const assetClassKeys = Object.keys(analysis.assetClassNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.assetClassNAVPercentage[b]["percentage"]) - parseFloat(analysis.assetClassNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });
  // const durationKeys = Object.keys(analysis.duration);
  const longestLength = Math.max(strategyKeys.length, durationKeys.length, longShortKeys.length, assetClassKeys.length);
  function reload(event: any, param: any) {
    window.location.href = url + param;
  }

  function reloadDuration(event: any, duration: string) {
    let durationMeaning: any = {
      "0 To 2": [0, 2],
      "2 To 5": [2, 5],
      "5 To 10": [5, 10],
      "10 To 30": [10, 30],
      "> 30": [30, null],
    };
    window.location.href = url + "duration_start=" + durationMeaning[duration][0] + "&duration_end=" + durationMeaning[duration][1];
  }
  // Create rows with data from each object or empty cells if no data is available
  const rows = [];
  for (let i = 0; i < longestLength; i++) {
    const strategy = strategyKeys[i];

    const duration = durationKeys[i];
    const longShort = longShortKeys[i];
    const curreny = currenciesNames[i];
    const assetClass = assetClassKeys[i];

    rows.push(
      <tr key={i} className="sticky-top">
        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {" "}
          {strategy || ""}
        </td>
        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy ? `${analysis.strategyNAVPercentage[strategy]["percentage"]} %` : ""}
        </td>

        <td style={{ borderRight: "1px solid grey" }} className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy ? `${analysis.strategyNAVPercentage[strategy]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {" "}
          {assetClass || ""}
        </td>
        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass ? `${analysis.assetClassNAVPercentage[assetClass]["percentage"]} %` : ""}
        </td>

        <td style={{ borderRight: "1px solid grey" }} className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass ? `${analysis.assetClassNAVPercentage[assetClass]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={duration && duration != "Total" ? "clickable-param" : ""} onClick={(event) => (duration && duration != "Total" ? reloadDuration(event, duration) : "")}>
          {duration || ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={duration && duration != "Total" ? "clickable-param" : ""} onClick={(event) => (duration && duration != "Total" ? reloadDuration(event, duration) : "")}>
          {duration ? `${analysis.duration[duration] ? analysis.duration[duration].dv01Sum.toLocaleString() : ""}` : ""}
        </td>

        <td>{longShort || ""}</td>

        <td>{longShort ? `${analysis.longShort[longShort] ? analysis.longShort[longShort].dv01Sum.toLocaleString() : ""}` : ""}</td>
        <td>{longShort ? `${analysis.longShort[longShort] ? analysis.longShort[longShort].intSum.toLocaleString() : ""}` : ""}</td>
        <td style={{ borderRight: "1px solid grey" }}>{longShort ? `${analysis.longShort[longShort] ? analysis.longShort[longShort].notionalSum.toLocaleString() : ""}` : ""}</td>

        <td className={curreny && curreny != "Total" ? "clickable-param" : ""} onClick={(event) => (curreny && curreny != "Total" ? reload(event, `currency=${curreny.split("USD/").length > 1 ? curreny.split("/")[1] : curreny.split("/")[0]}`) : "")}>
          {" "}
          {curreny || ""}
        </td>
        <td className={curreny && curreny != "Total" ? "clickable-param" : ""} onClick={(event) => (curreny && curreny != "Total" ? reload(event, `currency=${curreny.split("USD/").length > 1 ? curreny.split("/")[1] : curreny.split("/")[0]}`) : "")}>
          {curreny ? `${currencies[curreny]}` : ""}
        </td>
      </tr>
    );
  }

  return (
    <div className="statistics-table-container">
      <table className="table table-striped table-light table-hover statistics-table statistics-table-1" id="statistics">
        <tbody>
          <tr className="sticky-top">
            <td className="">Strategy</td>
            <td className="">Sum of NAV %</td>
            <td className="" style={{ borderRight: "1px solid grey" }}>
              {" "}
              Sum of (notional USD)
            </td>
            <td className="">Asset Class</td>
            <td className="">Sum of NAV %</td>
            <td className="" style={{ borderRight: "1px solid grey" }}>
              {" "}
              Sum of (notional USD)
            </td>

            <td className="">Duration</td>
            <td className="" style={{ borderRight: "1px solid grey" }}>
              Sum of DV01
            </td>

            <td className="">L/S</td>
            <td className="">Sum of DV01</td>
            <td className="">Sum of Int (Day)</td>
            <td className="" style={{ borderRight: "1px solid grey" }}>
              Sum of (notional USD)
            </td>

            <td className="">Currency</td>
            <td className="">Mid</td>
          </tr>

          {rows}
        </tbody>
      </table>
    </div>
  );
}

export default Statistics;
